<template>
  <div class="content">
    <base-header type="kakao" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-kakao d-inline-block mb-0">템플릿 휴면예정 목록</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <base-input additional-classes="width-100">
                <el-select
                  class="select-primary pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </base-input>
              <div class="form-inline" style="margin-bottom: 1.5rem;" >
                <div class="col-lg-3 pr-0">
                  <base-input v-if="session.clientType === 'R' && channelOptions.length > 1">
                    <el-select
                      v-model="search.channelId"
                      filterable
                      @change="restTemplateList(1)"
                      placeholder="발신프로필 선택">
                      <el-option v-for="option in channelOptions"
                                 :key="option.label"
                                 :label="option.label"
                                 :value="option.value">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-lg-4 pr-0">
                  <el-select v-model="search.condition"
                             filterable
                             placeholder="검색 조건">
                    <el-option v-for="option in selectOptions"
                               :key="option.label"
                               :label="option.label"
                               :value="option.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="col-lg-5 pl-1 pr-0">
                  <base-input group :input-group-classes="'width-100p'">
                    <input type="text" class="form-control" placeholder="검색어 입력" aria-label="검색어 입력" aria-describedby="button-addon2"
                           v-model="search.keyword"
                           @keydown.enter="restTemplateList(1)">
                    <div class="input-group-append">
                      <button class="btn btn-outline-primary" type="button" @click="restTemplateList(1)">검색</button>
                    </div>
                  </base-input>
                </div>
              </div>
            </div>
            <el-table :data="templateRestList"
                      :header-row-class-name="'thead-light'"
                      :header-cell-style="{'font-weight':'bold','font-size':'0.85rem'}"
                      :empty-text="'결과가 없습니다.'"
                      class="table-responsive table-flush">
              <el-table-column prop="kkoSeqno" label="고유번호" min-width="11%"></el-table-column>
              <el-table-column prop="sendProfileName" label="발신프로필" min-width="16%">
                <template slot-scope="scope">{{ scope.row.sendProfileName }} ({{scope.row.channelId}})</template>
              </el-table-column>
              <el-table-column prop="kkoTemplateCd" label="템플릿코드" min-width="16%"></el-table-column>
              <el-table-column prop="description" label="템플릿명" min-width="15%"></el-table-column>
              <el-table-column prop="restDate" label="휴면예정일" min-width="14%">
                <template slot-scope="scope"><span :class="approach(`${scope.row.restDate}`)">{{scope.row.restDate | convertDateFormat }}</span></template>
              </el-table-column>
            </el-table>
          </div>

          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                총 <span class="text-danger font-weight-bold">{{ total }}</span> 건
              </p>
            </div>

            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @input="restTemplateList">
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import { Table, TableColumn, Select, Option } from 'element-ui'
  import commJsUtilMixin from '@/shared/mixins/commJsUtil'
  import { default as commFlatpickrMixin } from '@/shared/mixins/commFlatpickrMixin'
  import commPaginationMixin from '@/shared/mixins/commPaginationMixin';
  import { USER_API_PREFIX } from '@/shared/util/const';
  import {mapGetters} from "vuex";
  import moment from "moment";

  export default {
    name: 'templateRestList',
    mixins: [ commFlatpickrMixin, commJsUtilMixin ,commPaginationMixin],
    components: {
      [Select.name] : Select,
      [Option.name] : Option,
      [Table.name]  : Table,
      [TableColumn.name]: TableColumn,
    },
    computed: {
      ...mapGetters({
        session: 'SessionStore/getSession'
      })
    },
    created() {
      this.getProfileList();
      this.restTemplateList();
    },
    data() {
      return {
        templateRestList : [] ,
        search: {
          condition   : 'DESCRIPTION',
          channel     : '',
          keyword     : ''
        },
        channelOptions: [],
        selectOptions: [
          {
            label: '템플릿명',
            value: 'DESCRIPTION'
          },
          {
            label: '템플릿코드',
            value: 'KKO_TEMPLATE_CD'
          }
        ]
      }
    },
    methods: {
      /**
       * 휴면예정 템플릿 목록 조회
       */
      restTemplateList(page) {
        let pageNo = page ? page : this.pagination.currentPage;
        this.$axios.get(`${USER_API_PREFIX}/template/rest/list`, {
          params: this.setParams(pageNo)
        }).then(res => {
          this.templateRestList = res.data.result.templateRestList; // 목록정보 세팅
          this.pagination.currentPage = pageNo;                           // 페이지 번호 세팅
          this.pagination.total = res.data.result.total;                  // 전체페이지수 세팅
        });
      },
      /**
       * 프로필 목록 조회 변경
       */
      getProfileList() {
        if (this.session.clientType === 'R') {
          this.$axios.get(`${USER_API_PREFIX}/template/profile`, {
            params: {}
          }).then(res => {
            this.channelOptions = res.data.result.profileList;
            this.channelOptions.unshift({
              value: '',
              label: '== 발신프로필 선택 =='
            })
          });
        }
      },
      /**
       * 파라미터 세팅
       */
      setParams(pageNo) {
        return {
          page            : pageNo,
          rows            : this.pagination.perPage,
          channelId        : this.search.channelId,
          searchCondition : this.search.condition,
          searchKeyword   : this.search.keyword
        }
      },
      /**
       * 휴면 임박 날짜 강조표시
       * @param value
       */
      approach(value){
        value = value.split(',');
        const limitDate = moment(new Date()).add(2,'weeks'); //오늘부터 2주 뒤 날짜
        let restDate = moment(new Date(value[0], value[1] - 1, value[2]));

        let diff = limitDate.diff(restDate, 'days');

        if(diff >= 0){
          return 'text-danger';
        }else{
          return '';
        }
      }
    }
  }
</script>
<style>
  .no-border-card {
    border-top: 0;
  }
</style>
